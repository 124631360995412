<template>
  <section>
    <screenshot name-pdf="Detalle Match Contratos"></screenshot>
    <b-card>
      <b-row>
        <b-col>
          <div v-for="item in data_contrato" :key="item.title">
            <b-row>
              <b-col sm="6">
                <ul class="list-group list-group-horizontal">
                  <li class="list-group-item w-25">
                    <b>{{ item.title }}</b>
                  </li>
                  <li class="list-group-item w-75">{{ item.description }}</li>
                </ul>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col>
        <ag-grid-table
          :configUrl="config2"
          :dataUrl="data2"
          :editUrl="edit2"
        ></ag-grid-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ag-grid-table
          :configUrl="config1"
          :dataUrl="data1"
          :editUrl="edit1"
        ></ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, VBPopover, VBTooltip } from "bootstrap-vue";
import Screenshot from "@/views/clients/fiordo/components/Screenshot";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import useApiServices from "@/services/useApiServices.js";
import axios from "@axios";
import MatchContratosActions from "./MatchContratosActions.vue";
export default {
  name: "Detalle Match Contratos",
  components: {
    BRow,
    BCol,
    BCard,
    VBPopover,
    VBTooltip,
    Screenshot,
    AgGridTable,
    MatchContratosActions: MatchContratosActions,
  },
  data() {
    return {
      config1: useApiServices.detalleMatchContratosConfig,
      data1: useApiServices.detalleMatchContratosData,
      edit1: useApiServices.detalleMatchContratosEdit,
      config2: useApiServices.detalleMatchContratosAsignadosConfig,
      data2: useApiServices.detalleMatchContratosAsignadosData,
      edit2: useApiServices.detalleMatchContratosAsignadosEdit,
      data_contrato: [
        { title: "Contrato", description: "PPS-00431" },
        { title: "Cliente", description: "Vitapro Chile" },
        {
          title: "Destino",
          description: "Chile",
        },
        { title: "Fecha Inicio", description: "marzo 2022" },
        { title: "Fecha Fin", description: "febrero 2023" },
        { title: "Meses", description: "12" },
        { title: "Toneladas", description: "1200" },
        { title: "Calidad", description: "H.Sardina Super Prime" },
        { title: "Parametros", description: " " },
      ],
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
};
</script>

<style></style>
